<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div *ngIf="stats" class="flex flex-column md:flex-row gap-4">
    <p-card header="{{'LABELS.clusters' | translate | titlecase}}" class="flex-1" styleClass="bg-primary">
        <div class="flex flex-row justify-content-between">
            <span class="text-3xl">{{stats.clusters}}</span>
            <i class="pi pi-server text-5xl"></i>
        </div>
    </p-card>
    <p-card header="{{'LABELS.organizations' | translate | titlecase}}" class="flex-1" styleClass="bg-primary">
        <div class="flex flex-row justify-content-between">
            <span class="text-3xl">{{stats.organizations}}</span>
            <i class="pi pi-sitemap text-5xl"></i>
        </div>
    </p-card>
    <p-card header="{{'LABELS.users' | translate | titlecase}}" class="flex-1" styleClass="bg-primary">
        <div class="flex flex-row justify-content-between">
            <span class="text-3xl">{{stats.users}}</span>
            <i class="pi pi-users text-5xl"></i>
        </div>
    </p-card>
</div>